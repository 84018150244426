import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Hits,
  Index,
  Configure,
  Stats,
  connectStateResults,
} from "react-instantsearch-dom"

import { Layout, MetaData } from "../components"
import {
  PageHit,
  BlogHit,
  ResourceHit,
  CustomCommunityHits,
  CustomPagination,
  SearchBox,
  DocHit,
  AlgoliaSearchBox,
} from "../components/search"
import { BoxedContainer, Section, Heading, ToggleButton } from "../elements"
import { GridStyles } from "../elements/Grid"
import { DefaultMessage } from "../elements/Messages"
import theme from "../styles/theme"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const docSearchClient = algoliasearch(
  process.env.GATSBY_DOCSEARCH_APP_ID,
  process.env.GATSBY_DOCSEARCH_KEY
)

const AllResults = connectStateResults(({ allSearchResults, children }) => {
  const hasResults =
    allSearchResults &&
    Object.values(allSearchResults).some(results => results.nbHits > 0)

  return !hasResults ? (
    <div>
      <DefaultMessage>No Results</DefaultMessage>
      <Index indexName="blog" />
      <Index indexName="resources" />
      <Index indexName="community_lib" />
      <Index indexName="pages" />
    </div>
  ) : (
    children
  )
})

const IndexResults = connectStateResults(
  ({ searchResults, children, activeContentType }) => {
    return searchResults && searchResults.nbHits !== 0 ? (
      <IndexWrapper>{children}</IndexWrapper>
    ) : activeContentType !== "all" ? (
      <DefaultMessage>No Results</DefaultMessage>
    ) : null
  }
)

const SearchPage = ({ data, location }) => {
  const [contentType, setContentType] = useState("all")
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />

      <Header>
        <BoxedContainer
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SearchBox
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />

          <Filters>
            <Filter
              active={contentType === "all"}
              onClick={() => setContentType("all")}
            >
              All
            </Filter>
            <Filter
              active={contentType === "blog"}
              onClick={() => setContentType("blog")}
            >
              Blog
            </Filter>
            <Filter
              active={contentType === "resources"}
              onClick={() => setContentType("resources")}
            >
              Resources
            </Filter>
            <Filter
              active={contentType === "community"}
              onClick={() => setContentType("community")}
            >
              Community
            </Filter>
            <Filter
              active={contentType === "docs"}
              onClick={() => setContentType("docs")}
            >
              Docs
            </Filter>
            <Filter
              active={contentType === "web"}
              onClick={() => setContentType("web")}
            >
              Other
            </Filter>
          </Filters>
        </BoxedContainer>
      </Header>

      {/* DocSearch */}
      <InstantSearch searchClient={docSearchClient} indexName={"traefik"}>
        <AlgoliaSearchBox searchQuery={searchQuery} />
        {(contentType === "all" || contentType === "docs") && (
          <Section.Container
            withPadding
            style={{
              background: theme.colors.background.light,
              paddingBottom: contentType === "docs" ? "80px" : "0",
            }}
          >
            <BoxedContainer>
              <IndexResults activeContentType={contentType}>
                <IndexMeta>
                  <MetaGroup>
                    <Heading variant="uppercase" as="h2">
                      Docs
                    </Heading>
                    <StyledStats />
                  </MetaGroup>
                  <MetaGroup>
                    <CustomPagination hitsPerPage={9} />
                  </MetaGroup>
                </IndexMeta>
                <Configure hitsPerPage={9} />
                <StyledHits hitComponent={DocHit} />
              </IndexResults>
            </BoxedContainer>
          </Section.Container>
        )}
      </InstantSearch>

      {/* Search Blogs, Community, Resources & Pages */}
      <InstantSearch
        searchClient={searchClient}
        indexName={"blog"}
        onSearchStateChange={searchState => {
          setSearchQuery(searchState.query)
        }}
      >
        <AlgoliaSearchBox searchQuery={searchQuery} />
        <Section.Container
          withPadding
          style={{
            background: theme.colors.background.light,
            display: contentType === "docs" ? "none" : "block",
          }}
        >
          <BoxedContainer>
            <AllResults>
              <Index indexName="blog">
                {(contentType === "all" || contentType === "blog") && (
                  <IndexResults activeContentType={contentType}>
                    <IndexMeta>
                      <MetaGroup>
                        <Heading variant="uppercase" as="h2">
                          Blog
                        </Heading>
                        <StyledStats />
                      </MetaGroup>
                      <MetaGroup>
                        <CustomPagination hitsPerPage={3} />
                      </MetaGroup>
                    </IndexMeta>
                    <Configure
                      hitsPerPage={3}
                      attributesToSnippet={["plaintext:25"]}
                    />
                    <StyledHits increaseRowSpacing hitComponent={BlogHit} />
                  </IndexResults>
                )}
              </Index>

              <Index indexName="resources">
                {(contentType === "all" || contentType === "resources") && (
                  <IndexResults activeContentType={contentType}>
                    <IndexMeta>
                      <MetaGroup>
                        <Heading variant="uppercase" as="h2">
                          Resources
                        </Heading>
                        <StyledStats />
                      </MetaGroup>
                      <MetaGroup>
                        <CustomPagination hitsPerPage={3} />
                      </MetaGroup>
                    </IndexMeta>
                    <Configure hitsPerPage={3} />
                    <StyledHits increaseRowSpacing hitComponent={ResourceHit} />
                  </IndexResults>
                )}
              </Index>

              <Index indexName="community_lib">
                {(contentType === "all" || contentType === "community") && (
                  <IndexResults activeContentType={contentType}>
                    <IndexMeta>
                      <MetaGroup>
                        <Heading variant="uppercase" as="h2">
                          Community
                        </Heading>
                        <StyledStats />
                      </MetaGroup>
                      <MetaGroup>
                        <CustomPagination hitsPerPage={6} />
                      </MetaGroup>
                    </IndexMeta>
                    <Configure hitsPerPage={6} />
                    <CustomCommunityHits />
                  </IndexResults>
                )}
              </Index>

              <Index indexName="pages">
                {(contentType === "all" || contentType === "web") && (
                  <IndexResults activeContentType={contentType}>
                    <IndexMeta>
                      <MetaGroup>
                        <Heading variant="uppercase" as="h2">
                          Other
                        </Heading>
                        <StyledStats />
                      </MetaGroup>
                      <MetaGroup>
                        <CustomPagination hitsPerPage={6} />
                      </MetaGroup>
                    </IndexMeta>
                    <Configure
                      hitsPerPage={6}
                      attributesToSnippet={["excerpt:25"]}
                    />
                    <StyledHits hitComponent={PageHit} />
                  </IndexResults>
                )}
              </Index>
            </AllResults>
          </BoxedContainer>
        </Section.Container>
      </InstantSearch>
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query {
    currentPage: ghostPage(slug: { eq: "search" }) {
      ...PageMetaFields
    }
  }
`

const Header = styled.section`
  position: relative;
  box-shadow: ${theme.shadows.darker};
  border-bottom: 1px solid ${theme.colors.borders.base};
  padding: 24px 0;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 21px 0 -5px;
`

const Filter = styled(ToggleButton)`
  margin: 5px;
`

const IndexWrapper = styled.section`
  margin: 0 0 80px;

  &:last-child {
    margin: 0;
  }
`

const IndexMeta = styled.div`
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MetaGroup = styled.div`
  display: flex;
  align-items: center;
`

const StyledStats = styled(Stats).attrs(props => ({
  translations: {
    stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
      return areHitsSorted && nbHits !== nbSortedHits
        ? `${nbSortedHits!.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found in ${processingTimeMS.toLocaleString()}ms`
        : `${nbHits.toLocaleString()} Results`
    },
  },
}))`
  font-size: 1.4rem;
  color: ${theme.colors.text.lighter};
  font-style: italic;
  margin: 0 0 0 16px;
`

const StyledHits = styled(Hits)`
  .ais-Hits-list {
    list-style: none;
    margin: 0;
    padding: 0;

    ${GridStyles}
  }

  .ais-Hits-item {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`
