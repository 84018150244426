import { css } from "styled-components"

import theme from "../../styles/theme"

const InputFieldStyles = css`
  box-sizing: border-box;
  border-radius: ${theme.radius.default};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${theme.colors.borders.base};
  background-color: ${theme.colors.background.base};
  padding: 10px;
  height: 48px;

  &:focus {
    border-color: ${theme.colors.primary.base};
  }
`

export { InputFieldStyles }
