import styled from "styled-components"

const DefaultMessage = styled.p`
  font-size: 1.6rem;
  color: ${p => p.theme.colors.text.base};
  font-weight: 500;
  margin: 0;
  text-align: center;
`

export { DefaultMessage }
