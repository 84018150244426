import React from "react"
import { connectHits } from "react-instantsearch-dom"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import CommunityHit from "./CommunityHit"

const CommunityHits = ({ hits }) => (
  <section
    style={{
      margin: "-12px",
    }}
  >
    <ResponsiveMasonry columnsCountBreakPoints={{ 700: 1, 900: 2, 1024: 3 }}>
      <Masonry>
        {hits.map(hit => (
          <CommunityHit key={hit.objectID} hit={hit} />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  </section>
)

const CustomCommunityHits = connectHits(CommunityHits)

export default CustomCommunityHits
