import React from "react"
import styled from "styled-components"
import { InputFieldStyles } from "../../elements/Forms/Input"

import { Search } from "@styled-icons/feather/Search"
import { X } from "@styled-icons/feather/X"

type Props = {
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
}

const SearchBox: React.FC<Props> = ({ searchQuery, setSearchQuery }) => {
  return (
    <Wrapper>
      <Input
        type="search"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        placeholder="Search here..."
        autoFocus
      />
      <SearchButton type="submit">
        <Search />
      </SearchButton>
      {searchQuery && (
        <ResetButton type="reset" onClick={() => setSearchQuery("")}>
          <X />
        </ResetButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
`

const Input = styled.input`
  ${InputFieldStyles}
  width: 100%;
  padding-left: 50px;
  box-sizing: border-box !important;

  &:focus + button[type="submit"] {
    svg {
      color: ${p => p.theme.colors.primary.base};
    }
  }
`

const SearchButton = styled.button`
  position: absolute;
  height: 100%;
  background: none;
  left: 0;
  top: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 21px;
    height: 21px;
    color: ${p => p.theme.colors.text.base};
  }
`

const ResetButton = styled.button`
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  background: none;

  svg {
    background: ${p => p.theme.colors.text.base};
    color: #fff;
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 100%;
    stroke-width: 2px;
  }

  &:hover {
    svg {
      background: ${p => p.theme.colors.text.dark};
    }
  }
`

export default SearchBox
