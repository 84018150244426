import React, { useMemo } from "react"
import styled from "styled-components"
import { connectPagination } from "react-instantsearch-dom"

import Arrow from "../../images/inline-svgs/arrow-right.svg"

const Pagination = ({ currentRefinement, nbPages, refine, hitsPerPage }) => {
  const count = useMemo(() => {
    return `${hitsPerPage * (currentRefinement - 1) + 1}-${
      hitsPerPage * currentRefinement
    }`
  }, [currentRefinement, hitsPerPage])

  return (
    <Wrapper aria-label="Search pagination">
      <Navigation>
        {currentRefinement > 1 && (
          <a
            href="#"
            onClick={event => {
              event.preventDefault()
              refine(currentRefinement - 1)
            }}
            aria-label="Previous page"
          >
            <ArrowLeft />
          </a>
        )}
        {currentRefinement < nbPages && (
          <a
            href="#"
            onClick={event => {
              event.preventDefault()
              refine(currentRefinement + 1)
            }}
            aria-label="Next page"
          >
            <Arrow />
          </a>
        )}
      </Navigation>
      {nbPages > 1 && <Count>{count}</Count>}
    </Wrapper>
  )
}

const CustomPagination = connectPagination(Pagination)

export default CustomPagination

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
`

const Navigation = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${p => p.theme.colors.text.base};
    margin: 0 4px;
  }
`

const ArrowLeft = styled(Arrow)`
  transform: rotate(180deg);
`

const Count = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.colors.text.lighter};
  margin: 2px 0 0 12px;
`
