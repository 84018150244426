import React, { useMemo } from "react"
import styled from "styled-components"
import { Highlight } from "react-instantsearch-dom"

import theme from "../../styles/theme"
import astGetCustomField from "../../utils/astGetCustomField"

import Icon from "../../images/inline-svgs/external-link.svg"

const CommunityHit = ({ hit }) => {
  const tags = useMemo(
    () => hit.tags.filter(tag => tag.visibility === "public").slice(1),
    [hit.tags]
  )
  const htmlAst = hit?.childHtmlRehype?.htmlAst
  const externalLink = useMemo(
    () => astGetCustomField(htmlAst, "externalLink"),
    [htmlAst]
  )
  const author = useMemo(() => astGetCustomField(htmlAst, "author"), [htmlAst])
  const language = useMemo(
    () => astGetCustomField(htmlAst, "language") || "English",
    [htmlAst]
  )
  const youtubeVideoID = useMemo(
    () => astGetCustomField(htmlAst, "youtubeVideoID"),
    [htmlAst]
  )

  return (
    <Container>
      <Wrapper href={externalLink} target="_blank" rel="noopener">
        {tags.length > 0 && (
          <Tags>
            {tags.map(tag => (
              <Tag key={tag.slug} className={`community-lib-tag--${tag.slug}`}>
                {tag.name}
              </Tag>
            ))}
          </Tags>
        )}

        {youtubeVideoID && (
          <FeaturedMedia>
            <iframe
              width="100%"
              height="200px"
              src={`https://www.youtube.com/embed/${youtubeVideoID}`}
              title={hit.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </FeaturedMedia>
        )}

        <Title>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Title>

        <Meta>
          <Author>{author}</Author>
          {author && <Separator>·</Separator>}
          <Language>{language}</Language>
        </Meta>

        {hit.excerpt && (
          <Excerpt>
            <Highlight attribute="excerpt" hit={hit} tagName="mark" />
          </Excerpt>
        )}

        {!youtubeVideoID && externalLink && (
          <Url>
            Read <StyledIcon />
          </Url>
        )}
      </Wrapper>
    </Container>
  )
}

export default CommunityHit

const Container = styled.article`
  width: 100%;
  padding: 12px;
`

const Wrapper = styled.a`
  display: block;
  border-radius: 8px;
  box-shadow: ${theme.shadows.base};
  border: solid 1px #ebeaeb;
  padding: 24px;
  background-color: #fff;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 8px;
`

const Tag = styled.div`
  font-size: 1.2rem;
  color: ${theme.colors.primary.base};
  background-color: rgba(30, 84, 213, 0.17);
  border: 1px solid rgba(30, 84, 213, 0.46);
  border-radius: 4px;
  line-height: 1;
  padding: 4px 8px;
  margin: 0 6px 6px 0;

  &.community-lib-tag--kubernetes,
  &.community-lib-tag--how-to,
  &.community-lib-tag--docker,
  &.community-lib-tag--k8s,
  &.community-lib-tag--k3s,
  &.community-lib-tag--gitops {
    color: #9d0fb0;
    border-color: rgba(157, 15, 176, 0.46);
    background-color: rgba(157, 15, 176, 0.17);
  }

  &.community-lib-tag--devops,
  &.community-lib-tag--ssl-tls,
  &.community-lib-tag--lets-encrypt,
  &.community-lib-tag--ecs,
  &.community-lib-tag--ingress,
  &.community-lib-tag--middlewares {
    color: #db7d11;
    border-color: rgba(219, 125, 17, 0.46);
    background-color: rgba(219, 125, 17, 0.17);
  }

  &.community-lib-tag--api,
  &.community-lib-tag--auth,
  &.community-lib-tag--cert-manager,
  &.community-lib-tag--swarm,
  &.community-lib-tag--traefik-v2,
  &.community-lib-tag--monitoring {
    color: #24a1c1;
    border-color: rgba(36, 161, 193, 0.46);
    background-color: rgba(36, 161, 193, 0.17);
  }
`

const FeaturedMedia = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 14px;
`

const Title = styled.h2`
  margin: 0 0 11px;
  font-size: 2rem;
  line-height: 1.3;

  a {
    color: currentColor;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`

const Author = styled.div`
  font-size: 1.4rem;
  color: ${theme.colors.primary.base};
`

const Separator = styled.span`
  padding: 0 4px;
  color: ${theme.colors.text.base};
`

const Language = styled.div`
  font-size: 1.4rem;
  text-transform: capitalize;
  color: ${theme.colors.primary.base};
`

const Excerpt = styled.p`
  font-size: 1.6rem;
  color: ${theme.colors.text.base};
  margin: 10px 0 0;
`

const Url = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin: 14px 0 0;
  display: flex;
`

const StyledIcon = styled(Icon)`
  margin: 0 0 0 4px;
  width: 20px;
`
