import React, { useMemo } from "react"
import styled from "styled-components"
import { Snippet, Highlight } from "react-instantsearch-dom"

import theme from "../../styles/theme"

const PageHit = ({ hit }) => {
  const pageUrl = useMemo(
    () =>
      hit.canonical_url ||
      (hit.primary_tag ? `/${hit.primary_tag?.slug}/${hit.slug}/` : false) ||
      `/${hit.slug}/`,
    [hit.title, hit.canonical_url, hit.primary_tag, hit.slug]
  )

  return (
    <Wrapper>
      <WrapperLink href={pageUrl}>
        <Title>
          {hit.meta_title ? (
            <Highlight attribute="meta_title" hit={hit} tagName="mark" />
          ) : (
            <Highlight attribute="title" hit={hit} tagName="mark" />
          )}
        </Title>
        <Excerpt>
          <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        </Excerpt>
      </WrapperLink>
    </Wrapper>
  )
}

export default PageHit

const Wrapper = styled.article`
  background: #fff;
  border: 1px solid ${theme.colors.borders.base};
  border-radius: ${theme.radius.default};
  height: 100%;
  box-shadow: ${theme.shadows.base};

  &:hover {
    border-color: ${theme.colors.primary.base};
  }
`

const WrapperLink = styled.a`
  display: block;
  padding: 24px;
  height: 100%;
  color: ${theme.colors.text.dark};
  transition: none;

  &:hover {
    color: ${theme.colors.primary.base};
  }
`

const Title = styled.h3`
  font-size: 2rem;
  margin: 0;
  color: currentColor;
`

const Excerpt = styled.p`
  font-size: 1.6rem;
  color: ${theme.colors.text.base};
  margin: 8px 0 0;
`
