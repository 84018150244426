import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Snippet, Highlight } from "react-instantsearch-dom"

import { Middot } from "../../elements"
import { LinkWithArrowStyles } from "../../elements/Links"
import theme from "../../styles/theme"

const BlogHit = ({ hit }) => (
  <Wrapper>
    <WrapperLink to={`/blog/${hit.slug}/`}>
      <FeatureImage src={hit.feature_image} alt={hit.title} />

      <Title>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Title>

      <Meta>
        {hit.primary_author && (
          <>
            <Author>
              <Avatar
                src={hit.primary_author.profile_image}
                alt={hit.primary_author.name}
              />
              <span>{hit.primary_author.name}</span>
            </Author>
            <Middot />
          </>
        )}
        {hit.tags[1] && (
          <>
            <Tag>{hit.tags[1].name}</Tag>
            <Middot />
          </>
        )}
        {hit.published_at_pretty && <time>{hit.published_at_pretty}</time>}
      </Meta>

      <Excerpt>
        <Snippet attribute="plaintext" hit={hit} tagName="mark" />
      </Excerpt>

      <ReadMore>Read more</ReadMore>
    </WrapperLink>
  </Wrapper>
)

export default BlogHit

const Wrapper = styled.article`
  height: 100%;
`

const WrapperLink = styled(Link)`
  display: block;
  height: 100%;
`

const FeatureImage = styled.img`
  display: block;
  border-radius: ${theme.radius.large};
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin: 0 0 24px;
`

const Title = styled.h3`
  font-size: 2rem;
  margin: 0 0 12px;
`

const Meta = styled.div`
  margin: 0 0 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.4rem;
  color: ${theme.colors.text.base};
`

const Author = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  margin: 0 8px 0 0;
`

const Tag = styled.span`
  color: ${theme.colors.primary.base};
`

const Excerpt = styled.p`
  font-size: 1.6rem;
  color: ${theme.colors.text.base};
  margin: 0 0 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`

const ReadMore = styled.div`
  ${LinkWithArrowStyles}
`
