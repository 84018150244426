import React from "react"
import styled from "styled-components"
import { Highlight } from "react-instantsearch-dom"

import theme from "../../styles/theme"

const DocHit = ({ hit }) => {
  // Get the index of last available value from hierarchy levels
  const headingLevel =
    6 -
    Object.values(hit.hierarchy)
      .reverse()
      .findIndex(element => element !== null)

  return (
    <Wrapper>
      <WrapperLink href={hit.url}>
        <Title>
          <Highlight
            attribute={`hierarchy.lvl${headingLevel}`}
            hit={hit}
            tagName="mark"
          />
        </Title>
        <Breadcrumbs>
          {Object.values(hit.hierarchy).map((val, index) => {
            if (val) {
              return (
                <Breadcrumb key={index}>
                  <span>
                    <Highlight
                      attribute={`hierarchy.lvl${index}`}
                      hit={hit}
                      tagName="mark"
                    />
                  </span>
                  <Separator>&rsaquo;</Separator>
                </Breadcrumb>
              )
            } else return null
          })}
        </Breadcrumbs>
      </WrapperLink>
    </Wrapper>
  )
}

export default DocHit

const Wrapper = styled.article`
  background: #fff;
  border: 1px solid ${theme.colors.borders.base};
  border-radius: ${theme.radius.default};
  height: 100%;
  box-shadow: ${theme.shadows.base};

  &:hover {
    border-color: ${theme.colors.primary.base};
  }
`

const WrapperLink = styled.a`
  display: block;
  padding: 24px;
  height: 100%;
  color: ${theme.colors.text.dark};
  transition: none;

  &:hover {
    color: ${theme.colors.primary.base};
  }
`

const Title = styled.h3`
  font-size: 2rem;
  margin: 0;
  color: currentColor;
`

const Breadcrumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0;
  color: ${theme.colors.text.base};
`

const Breadcrumb = styled.div`
  display: inline-flex;
  align-items: center;

  &:last-child > span:last-of-type {
    display: none;
  }
`

const Separator = styled.span`
  margin: 0 8px;
  color: ${theme.colors.text.lighter};
  font-size: 2rem;
`
