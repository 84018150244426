import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { Highlight } from "react-instantsearch-dom"

import { LinkWithArrowStyles } from "../../elements/Links"
import theme from "../../styles/theme"

const ResourceHit = ({ hit }) => (
  <Wrapper>
    <WrapperLink to={`/resources/${hit.slug}/`}>
      <FeatureImage src={hit.feature_image} alt={hit.title} />
      {hit.tags[1] && (
        <Tag className={`resource-hit--${hit.tags[1]?.slug}`}>
          {hit.tags[1].name}
        </Tag>
      )}
      <Title>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Title>
      <ReadMore>Read more</ReadMore>
    </WrapperLink>
  </Wrapper>
)

export default ResourceHit

const Wrapper = styled.article`
  height: 100%;
`

const WrapperLink = styled(Link)`
  display: block;
  height: 100%;
`

const FeatureImage = styled.img`
  display: block;
  border-radius: ${theme.radius.large};
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin: 0 0 24px;
`

const Tag = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0 0 8px;
  text-transform: uppercase;

  &.resource-hit--white-paper {
    color: ${p => p.theme.colors.accent.orange.base};
  }

  &.resource-hit--datasheet {
    color: ${p => p.theme.colors.accent.teal.base};
  }

  &.resource-hit--case-study {
    color: ${p => p.theme.colors.accent.purple.base};
  }

  &.resource-hit--webinar {
    color: ${p => p.theme.colors.accent.magenta.base};
  }

  &.resource-hit--video {
    color: ${p => p.theme.colors.accent.turquoise.base};
  }
`

const Title = styled.h3`
  font-size: 2rem;
  margin: 0 0 12px;
`

const ReadMore = styled.div`
  ${LinkWithArrowStyles}
`
