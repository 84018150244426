import React, { useEffect } from "react"
import { connectSearchBox } from "react-instantsearch-dom"

const SearchBox = ({ refine, searchQuery }) => {
  useEffect(() => {
    refine(searchQuery)
  }, [searchQuery])

  return null
}

const AlgoliaSearchBox = connectSearchBox(SearchBox)

export default AlgoliaSearchBox
